<template>
  <v-row v-if="template.website">
    <v-col>
      <div v-for="item of template.website.sections" :key="item.title">
        <div v-if="item.type === 1">
          <SectionType1 :section="item" :id="item.id"/>
        </div>
        <div v-if="item.type === 2">
          <SectionType2 :section="item" :id="item.id"/>
        </div>
        <div v-if="item.type === 3">
          <SectionType3 :section="item" :id="item.id"/>
        </div>
        <div v-if="item.type === 4">
          <SectionType4 :section="item" :id="item.id"/>
        </div>
        <div v-if="item.type === 5">
          <SectionType5 :section="item" :id="item.id"/>
        </div>
        <div v-if="item.type === 6">
          <SectionType6 :section="item" :id="item.id"/>
        </div>
        <div v-if="item.type === 7">
          <SectionType7 :section="item" :id="item.id"/>
        </div>
      </div>
      <HeaderSection/>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderSection from './HeaderSection.vue'
import SectionType1 from './SectionType1.vue'
import SectionType2 from './SectionType2.vue'
import SectionType3 from './SectionType3.vue'
import SectionType4 from './SectionType4.vue'
import SectionType5 from './SectionType5.vue'
import SectionType6 from './SectionType6.vue'
import SectionType7 from './SectionType7.vue'

export default {
  name: 'WebSite',
  components:{
    HeaderSection,
    SectionType1,
    SectionType2,
    SectionType3,
    SectionType4,
    SectionType5,
    SectionType6,
    SectionType7
  },
  computed: {
    ...mapGetters(['template']),
  },
  methods: {
    ...mapActions(['loadTemplate']),
    updateFavicon(url) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = url;
    }
  },
  created() {
    this.loadTemplate().then(() => {
      document.title = this.template.website.browserName;
      this.updateFavicon(this.template.website.favicon);
    });
  }
}
</script>
