<template>
  <div     :style="mobile ? section.sectionMobileStyle : section.sectionStyle"
  >
    <div class="sectionTitle">
      <h1 :style="section.titleStyle" v-html="section.title"></h1>
    </div>

    <div :style="section.textStyle" v-html="section.text"></div>

    <div :style="section.imagesStyle">
      <div v-for="(item, i) in section.images" :key="i">
        <a :href="item.redirect" target="blank">
          <img :src="item.url" :width="item.width" :style="item.style" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionType7",
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    mobile: false,
  }),
  mounted() {
    this.mobile = window.innerWidth < 1000 || screen.width < 1000;
  },
  watch: {
    window() {
      this.mobile = window.innerWidth < 1000 || screen.width < 1000;
    },
  },
};
</script>

<style scoped>
.sectionTitle {
  text-align: center;
  margin-inline: 50px;
}
</style>
