<template>
  <v-app>
    <v-main>
      <WebSite/>
    </v-main>
  </v-app>
</template>

<script>
import WebSite from './components/WebSite.vue'

export default {
  name: 'App',
  components: {
    WebSite,
  }
}
</script>
