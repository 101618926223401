import { createStore } from 'vuex';

const templatePaths = {
    'webcatportal': () => import('@/assets/templates/webcatportal_template.json'),
    'portfoliobeatrizsaccaro': () => import('@/assets/templates/portfoliobeatrizsaccaro_template.json'),
    'transfersac': () => import('@/assets/templates/transfersac_template.json'),
    'webcat': () => import('@/assets/templates/webcatportal_template.json'),
    'portfoliofabiovillar': () => import('@/assets/templates/portfoliofabiovillar_template.json'),
    'barbeariamodelo': () => import('@/assets/templates/barbeariamodelo_template.json'),
    'salaomodelo': () => import('@/assets/templates/salaomodelo_template.json'),
};

const store = createStore({
    state: {
        template: {}
    },
    mutations: {
        // Mutation to set the template data
        SET_TEMPLATE(state, template) {
            state.template = template;
        }
    },
    actions: {
        // Action to dynamically load the template based on the URL and commit the mutation
        async loadTemplate({ commit }) {
            let domain = window.location.href;
            const hostname = new URL(domain).hostname;
            const templateName = hostname.split('.')[0].replace('www.', '');
            let loadTemplate = templatePaths[templateName] || templatePaths['webcatportal'];
            try {
              const template = await loadTemplate();
              commit('SET_TEMPLATE', template.default);
            } catch (error) {
              const defaultTemplate = await templatePaths['default']();
              commit('SET_TEMPLATE', defaultTemplate.default);
            }
          }
    },
    getters: {
        // Getter to access the template data from the state
        template: (state) => {return state.template},
    },
});

export default store;
