<template>
  <v-container v-if="template.website">
    <v-app-bar :color="template.website.color1" prominent>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <img
        v-if="template.website.showLogo"
        width="60px"
        :src="template.website.logoUrl"
      />
      <v-toolbar-title
        :style="template.website.nameStyle"
        v-html="template.website.name"
      ></v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :location="$vuetify.display.mobile ? 'bottom' : undefined"
      temporary
      :color="template.website.color2"
      width="fit-content"
    >
      <v-list class="list" :style="template.website.headerListStyle">
        <template
          :key="item.title"
          v-for="(item, index) in template.website.sections"
        >
          <v-list-item
            prepend-icon="mdi-plus"
            :href="'#' + item.id"
            @click="handleItemClick(item)"
          >
          <v-list-item-content>
            <v-list-item-title :style="template.website.headerListStyle">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < template.website.sections.length - 1"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HeaderSection",
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    ...mapGetters(["template"]),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    ...mapActions(["loadTemplate"]),
    handleItemClick() {
      this.drawer = false;
    },
  },
  created() {
    this.loadTemplate();
  },
};
</script>

<style>
@media (max-width: 992px) {
  .list {
    margin-top: 50px;
  }
}
</style>
