<template>
  <div :style="mobile ? section.sectionMobileStyle : section.sectionStyle">
    <div class="sectionContent">
      <h1 :style="section.titleStyle" v-html="section.title"></h1>

      <div :style="section.textStyle" v-html="section.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionType1",
  data: () => ({
    mobile: false,
  }),
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.mobile = window.innerWidth < 1000 || screen.width < 1000;
  },
  watch: {
    window(){
      this.mobile = window.innerWidth < 1000 || screen.width < 1000;
    }
  }
}
</script>

<style scoped>
.sectionContent {
  text-align: center;
  margin-inline: 50px;
  margin-block: 100px;
}
</style>
