<template>
  <div
    class="section"
    :style="mobile ? section.sectionMobileStyle : section.sectionStyle"
  >
    <div class="sectionContent">
      <h1 :style="section.titleStyle" v-html="section.title"></h1>
      <img :src="section.image" :style="section.imageStyle" alt="Image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionType4",
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    mobile: false,
  }),
  mounted() {
    this.mobile = window.innerWidth < 1000 || screen.width < 1000;
  },
  watch: {
    window() {
      this.mobile = window.innerWidth < 1000 || screen.width < 1000;
    },
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .section {
    min-height: 700px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sectionContent {
    text-align: center;
    margin-block: 100px;
  }
}
@media (max-width: 992px) {
  .section {
    min-height: 500px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sectionContent {
    text-align: center;
    margin-inline: 50px;
    margin-block: 100px;
  }
}
</style>
