<template>
  <div
    class="section"
    :style="mobile ? section.sectionMobileStyle : section.sectionStyle"
  >
    <div class="sectionContent">
      <h1 :style="section.titleStyle" v-html="section.title"></h1>
      <div class="sectionInfo">
        <a
          v-if="section.whatsapp"
          aria-label="Chat on WhatsApp"
          :href="'https://wa.me/' + section.whatsapp"
          class="sectionInfoObject"
        >
          <v-icon
            class="mdi mdi-whatsapp text-green"
            style="font-size: 50px"
          ></v-icon>
        </a>
        <a
          class="sectionInfoObject"
          v-if="section.email && section.email.length > 0"
          :href="'mailto:' + section.email"
        >
          <v-icon class="mdi mdi-email" style="font-size: 50px"></v-icon
        ></a>
        <a
          class="sectionInfoObject"
          v-if="section.instagram && section.instagram.length > 0"
          :href="'https://www.instagram.com/' + section.instagram"
          target="_blank"
        >
          <v-icon class="mdi mdi-instagram" style="font-size: 50px"> </v-icon>
        </a>
        <a
          class="sectionInfoObject"
          v-if="section.facebook && section.facebook.length > 0"
          :href="section.facebook"
          target="_blank"
        >
          <v-icon class="mdi mdi-facebook text-primary" style="font-size: 50px">
          </v-icon>
        </a>
        <a
          class="sectionInfoObject"
          v-if="section.youtube && section.youtube.length > 0"
          :href="section.youtube"
          target="_blank"
        >
          <v-icon class="mdi mdi-youtube" style="font-size: 50px"></v-icon>
        </a>
        <a
          class="sectionInfoObject"
          v-if="section.tiktok && section.tiktok.length > 0"
          :href="section.tiktok"
          target="_blank"
        >
          <v-icon class="mdi mdi-music-note" style="font-size: 50px"></v-icon>
        </a>
        <a
          class="sectionInfoObject"
          v-if="section.linkedin && section.linkedin.length > 0"
          :href="section.linkedin"
          target="_blank"
        >
          <v-icon
            class="mdi mdi-linkedin text-blue"
            style="font-size: 50px"
          ></v-icon>
        </a>
      </div>
      <img
        v-if="section.showLogo"
        :width="section.logoWidth"
        :src="template.website.logoUrl"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SectionType5",
  computed: {
    ...mapGetters(["template"]),
  },
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    mobile: false,
  }),
  mounted() {
    this.mobile = window.innerWidth < 1000 || screen.width < 1000;
  },
  watch: {
    window() {
      this.mobile = window.innerWidth < 1000 || screen.width < 1000;
    },
  },
  methods: {
    ...mapActions(["loadTemplate"]),
    handleItemClick() {
      this.drawer = false;
    },
  },
  created() {
    this.loadTemplate();
  },
};
</script>

<style scoped>
.sectionInfoObject {
  margin-top: 20px;
  padding: 1rem;
  border-radius: 15px;
}
@media (min-width: 992px) {
  .section {
    min-height: 700px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sectionContent {
    text-align: center;
    margin-inline: 300px;
  }
  .sectionInfo {
    display: flex row;
    flex-direction: column;
    margin-bottom: 100px;
  }
}
@media (max-width: 992px) {
  .section {
    min-height: 500px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sectionContent {
    text-align: center;
    margin-inline: 50px;
    margin-block: 100px;
  }
  .sectionInfo {
    display: flex row;
    flex-direction: column;
    margin-bottom: 100px;
  }
}
</style>
